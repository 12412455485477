import React from 'react';
import Presenter from './presenter';

export default (props) => {
  function getContacts() {
    const contacts = props.presenters.map((presenter, index) => {
      return `<a href="mailto:${presenter.email}">${presenter.friendlyName}</a>`;
    });

    let contactString = contacts.join(', ');

    contactString = contactString.replace(/,(?=[^,]*$)/, ' or');

    return { __html: contactString };
  }

  return (
    <section className="contact">
      <div className="inner centre-column">
        <h4>Presenters</h4>
        <div className="row">
          {props.presenters.map((presenter, index) => (
            <div className="col" key={index}>
              <Presenter key={index} name={presenter.name} image={presenter.image} />
            </div>
          ))}
        </div>
        <h5>
          Want to talk more about transforming your business? Get in touch with{' '}
          <span dangerouslySetInnerHTML={getContacts()} /> for&nbsp;a&nbsp;chat.
        </h5>
      </div>
    </section>
  );
};
