import React, { useRef, useState } from 'react';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      companyName: '',
      email: '',
      feedback: '',
      subscribe: true,
      subscribeTo: props.formCheckboxText
        ? props.formCheckboxText
        : 'Let me know about future tools, training or activities from the Isobar team.',
      page: props.slug,
      showButton: false
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = { ...this.state };

    delete formData['showButton'];

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData
      })
    })
      .then(() => {
        this.setState(
          {
            showButton: true
          },
          () => {
            window.location.hash = '#form-section';
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleCheckbox = (e) => this.setState({ [e.target.name]: !this.state[e.target.name] });

  handleDownload = () => {
    if (ga) {
      ga('send', 'event', 'PDF', 'Download', `${this.props.slug}-form`);
    }
  };

  render() {
    const { name, companyName, email, feedback, subscribe } = this.state;
    const formName = `isobar-training-form`;
    const showButtonClass = this.state.showButton ? ' show-button' : '';

    return (
      <section className="form-section bg-light-grey" id="form-section">
        <div className="inner inner--small">
          <form
            name={formName}
            method="post"
            action={`/${this.props.slug}`}
            data-netlify="true"
            netlify-honeypot="bot-field"
            className={`form${showButtonClass}`}
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            {this.props.formCopyHeading && (
              <p className="lead">{this.props.formCopyHeading.formCopyHeading}</p>
            )}
            {this.props.formCopy && <p>{this.props.formCopy.formCopy}</p>}

            <div className="form-row">
              <label htmlFor="name"> Name* </label>
              <input
                type="text"
                name="name"
                required
                placeholder="Your name"
                value={name}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row">
              <label htmlFor="companyName"> Company Name* </label>
              <input
                type="text"
                name="companyName"
                required
                placeholder="Your company name"
                value={companyName}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row">
              <label htmlFor="email"> Email* </label>
              <input
                type="email"
                name="email"
                required
                placeholder="Your work email"
                value={email}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row">
              <label htmlFor="feedback"> Feedback </label>
              <textarea
                name="feedback"
                placeholder="What did you think?"
                value={feedback}
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className="form-row">
              <label className="tickbox" htmlFor="subscribe">
                <input
                  type="checkbox"
                  name="subscribe"
                  checked={subscribe}
                  value={subscribe}
                  onChange={this.handleCheckbox}
                />
                {this.props.formCheckboxText
                  ? this.props.formCheckboxText
                  : 'Let me know about future tools, training or activities from the Isobar team.'}
              </label>
              <input
                type="text"
                name="subscribeTo"
                defaultValue={
                  this.props.formCheckboxText
                    ? this.props.formCheckboxText
                    : 'Let me know about future tools, training or activities from the Isobar team.'
                }
                className="hidden"
              />
              <input type="text" name="page" defaultValue={this.props.slug} className="hidden" />
            </div>
            <div className="form-row">
              <button type="submit">Submit to Download</button>
            </div>
          </form>
          <div className={`response${showButtonClass}`}>
            {this.state.showButton && (
              <div>
                {this.props.deck ? (
                  <div>
                    <h4>
                      Thanks for expressing your interest! You can download the presentation below
                    </h4>
                    <a
                      href={this.props.deck}
                      className="button"
                      onClick={this.handleDownload}
                      target="_blank"
                    >
                      Download Presentation
                    </a>
                  </div>
                ) : (
                  <div>
                    <h4>Thanks for expressing your interest!</h4>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
