import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Hero from '../components/hero';
import VideoSection from '../components/video-section';
import Form from '../components/form';
import PresentersSection from '../components/presenters-section';

class Template404 extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title="404 | Page not found">
        <Hero title="404" introduction="Sorry this page could not be found" />
      </Layout>
    );
  }
}

export default Template404;
