import React from 'react';
import RichText from './richtext';

export default (props) => {
  let introduction;

  if (props.introduction) {
    if (typeof props.introduction === 'object' && 'json' in props.introduction) {
      introduction = <RichText {...props.introduction} />;
    } else {
      introduction = props.introduction;
    }
  }

  if (props.image) {
    return (
      <section className="hero bg-light-grey has-bgimage">
        <img src={props.image.src} alt="" className="bg-image" />
        <div className="inner">
          <div className="content-half">
            <h1>{props.title}</h1>
            <div className="subcopy">{introduction}</div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero bg-light-grey">
      <div className="inner inner--small">
        <h1>{props.title}</h1>
        <div className="subcopy">{introduction}</div>
      </div>
    </section>
  );
};
