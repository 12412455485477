import React from 'react';

export default (props) => {
  if (!props.url) {
    return '';
  }

  return (
    <section className="template video-section">
      <div className="inner">
        <div className="iframe-container">
          <iframe
            width="1280"
            height="720"
            src={props.url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};
